import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "files", fluid: "", tag: "section" } },
    [
      _vm.userType == _vm.userTypeEnum.Investor
        ? _c("h1", { staticClass: "extract-investor-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("investment_data")) + " "),
          ])
        : _vm._e(),
      _vm.userType == _vm.userTypeEnum.Investor
        ? _c("p", { staticClass: "extract-investor-sub-title" }, [
            _vm._v(" " + _vm._s(_vm.$t("check_your_extract_sub_title")) + " "),
          ])
        : _vm._e(),
      _vm.listOfExtracts.length > 0 && _vm.userType === _vm.userTypeEnum.Admin
        ? _c("div", { staticClass: "reprocess-extract-container" }, [
            _c(
              "div",
              { staticClass: "reprocess-extract-input-container" },
              [
                _c(VSelect, {
                  staticClass: "mt-6",
                  attrs: {
                    label: "Select",
                    items: _vm.listOfExtracts,
                    "item-text": _vm.text,
                    variant: "solo",
                    "return-object": "",
                  },
                  model: {
                    value: _vm.selectedExtract,
                    callback: function ($$v) {
                      _vm.selectedExtract = $$v
                    },
                    expression: "selectedExtract",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "reprocess-extract-button-container" },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "ma-2",
                    attrs: {
                      loading: _vm.reprocessExtractLoading,
                      color: "primary",
                      disabled: !_vm.selectedExtract,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.reprocessExtracts()
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("process_extract")) + " "),
                    _vm.userType === _vm.userTypeEnum.Admin
                      ? _c(VIcon, [_vm._v("mdi-file-arrow-up-down")])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.userType === _vm.userTypeEnum.Admin &&
      !_vm.loading &&
      _vm.investorInvestmentsReport
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "mt-4",
                  style:
                    "background-color: var(--secondary); border-radius: 5px",
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "pa-2 mb-0",
                      staticStyle: {
                        color: "var(--white)",
                        "font-weight": "normal",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("investments_report")) + " ")]
                  ),
                ]
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("download")))]),
                  ]),
                ]),
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "table-extract-td" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.investorInvestmentsReport.reportName) +
                          " "
                      ),
                    ]),
                    _c(
                      "td",
                      { staticClass: "table-extract-td" },
                      [
                        _c(
                          VBtn,
                          {
                            attrs: {
                              icon: "",
                              color: "primary",
                              "min-width": "0",
                              small: "",
                              loading:
                                _vm.extractDownloadLoading &&
                                _vm.investorInvestmentsReport.id ===
                                  _vm.extractToDownload,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDownloadExtract(
                                  _vm.investorInvestmentsReport.id
                                )
                              },
                            },
                          },
                          [
                            _c(VIcon, { attrs: { small: "" } }, [
                              _vm._v("mdi-download"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.userType === _vm.userTypeEnum.Admin && !_vm.loading
        ? _c(
            "div",
            {
              staticClass: "mt-4",
              style: "background-color: var(--secondary); border-radius: 5px",
            },
            [
              _c(
                "p",
                {
                  staticClass: "pa-2 mb-0",
                  staticStyle: {
                    color: "var(--white)",
                    "font-weight": "normal",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$tc("extract", 2)) + " ")]
              ),
            ]
          )
        : _vm._e(),
      !_vm.loading
        ? _c(
            VSimpleTable,
            {
              class: {
                "extract-table": _vm.userType === _vm.userTypeEnum.Investor,
              },
            },
            [
              _c(
                "thead",
                {
                  class: {
                    "extract-table-thead":
                      _vm.userType === _vm.userTypeEnum.Investor,
                  },
                },
                [
                  _vm.reportType === _vm.ReportTypeEnum.Annual
                    ? _c("tr", [
                        _c("th", [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]),
                        _c("th", [
                          _vm._v(
                            _vm._s(
                              _vm.userType === _vm.userTypeEnum.Admin
                                ? _vm.$t("download")
                                : ""
                            )
                          ),
                        ]),
                      ])
                    : _vm.userType === _vm.userTypeEnum.Admin
                    ? _c("tr", [
                        _c("th", [_vm._v(" " + _vm._s(_vm.$t("name")) + " ")]),
                        _c("th", [
                          _vm._v(" " + _vm._s(_vm.$t("company_single")) + " "),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("download")))]),
                      ])
                    : _vm.userType === _vm.userTypeEnum.Investor
                    ? _c("tr", [
                        _c("th", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("vehicle_title_single")) + " "
                          ),
                        ]),
                        _c("th", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("rounds_time_title")) + " "
                          ),
                        ]),
                        _c("th"),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.reportType === _vm.ReportTypeEnum.Annual
                ? _c(
                    "tbody",
                    _vm._l(_vm.reports, function (report, i) {
                      return _c("tr", { key: i }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "table-extract-td table-extract-td-vehicle",
                          },
                          [
                            _vm.userType === _vm.userTypeEnum.Investor
                              ? _c("font-awesome-icon", {
                                  staticClass: "table-icon-file",
                                  attrs: { icon: "fa-solid fa-file-invoice" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDownloadExtract(
                                        report.id
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" " + _vm._s(report.reportName) + " "),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "table-extract-td" },
                          [
                            _vm.userType === _vm.userTypeEnum.Admin
                              ? _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      icon: "",
                                      color: "primary",
                                      "min-width": "0",
                                      small: "",
                                      loading:
                                        _vm.extractDownloadLoading &&
                                        report.id === _vm.extractToDownload,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownloadExtract(
                                          report.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.userType === _vm.userTypeEnum.Admin
                                      ? _c(VIcon, { attrs: { small: "" } }, [
                                          _vm._v("mdi-download"),
                                        ])
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.userType === _vm.userTypeEnum.Investor
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "table-extract-td-link-download",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDownloadExtract(
                                          report.id
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.extractDownloadLoading &&
                                    report.id === _vm.extractToDownload
                                      ? _c(VProgressCircular, {
                                          attrs: {
                                            indeterminate: "",
                                            size: "20",
                                            color: "primary",
                                          },
                                        })
                                      : _c("span", [
                                          _vm._v(_vm._s(_vm.$t("download"))),
                                        ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm.userType === _vm.userTypeEnum.Admin
                ? _c(
                    "tbody",
                    _vm._l(_vm.reports, function (report, i) {
                      return _c("tr", { key: i }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "table-extract-td table-extract-td-vehicle",
                          },
                          [_vm._v(" " + _vm._s(report.reportName) + " ")]
                        ),
                        _c("td", { staticClass: "table-extract-td" }, [
                          _vm._v(" " + _vm._s(report.companyName) + " "),
                        ]),
                        _c(
                          "td",
                          { staticClass: "table-extract-td" },
                          [
                            _c(
                              VBtn,
                              {
                                attrs: {
                                  icon: "",
                                  color: "primary",
                                  "min-width": "0",
                                  small: "",
                                  loading:
                                    _vm.extractDownloadLoading &&
                                    report.id === _vm.extractToDownload,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDownloadExtract(report.id)
                                  },
                                },
                              },
                              [
                                _c(VIcon, { attrs: { small: "" } }, [
                                  _vm._v("mdi-download"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _vm.userType === _vm.userTypeEnum.Investor
                ? _c(
                    "tbody",
                    _vm._l(_vm.reports, function (report, i) {
                      return _c("tr", { key: i }, [
                        _c(
                          "td",
                          {
                            staticClass:
                              "table-extract-td table-extract-td-vehicle",
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass: "table-icon-file",
                              attrs: { icon: "fa-solid fa-file-invoice" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDownloadExtract(report.id)
                                },
                              },
                            }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.userType === _vm.userTypeEnum.Investor
                                    ? report.vehicleName
                                    : report.reportName
                                ) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c("td", { staticClass: "table-extract-td" }, [
                          _vm._v(" " + _vm._s(report.reportDate) + " "),
                        ]),
                        _c("td", { staticClass: "table-extract-td" }, [
                          _c(
                            "span",
                            {
                              staticClass: "table-extract-td-link-download",
                              on: {
                                click: function ($event) {
                                  return _vm.handleDownloadExtract(report.id)
                                },
                              },
                            },
                            [
                              _vm.extractDownloadLoading &&
                              report.id === _vm.extractToDownload
                                ? _c(VProgressCircular, {
                                    attrs: {
                                      indeterminate: "",
                                      size: "20",
                                      color: "primary",
                                    },
                                  })
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.$t("download"))),
                                  ]),
                            ],
                            1
                          ),
                        ]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            { staticClass: "item-center" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          ),
      _vm.userType === _vm.userTypeEnum.Investor &&
      _vm.reportType === _vm.ReportTypeEnum.Annual
        ? _c(
            "ul",
            { staticClass: "extract-investor-list" },
            _vm._l(_vm.reports, function (report, i) {
              return _c(
                "li",
                {
                  key: i,
                  staticClass: "extract-investor-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleDownloadExtract(report.id)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "extract-investor-vehicle" }, [
                    _vm._v(_vm._s(report.reportName)),
                  ]),
                  _vm.extractDownloadLoading &&
                  report.id === _vm.extractToDownload
                    ? _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          size: "20",
                          color: "primary",
                        },
                      })
                    : _c("span", { staticClass: "extract-investor-download" }, [
                        _vm._v(_vm._s(_vm.$t("download"))),
                      ]),
                ],
                1
              )
            }),
            0
          )
        : _vm.userType === _vm.userTypeEnum.Investor
        ? _c(
            "ul",
            { staticClass: "extract-investor-list" },
            _vm._l(_vm.reports, function (report, i) {
              return _c(
                "li",
                {
                  key: i,
                  staticClass: "extract-investor-item",
                  on: {
                    click: function ($event) {
                      return _vm.handleDownloadExtract(report.id)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "extract-investor-vehicle" }, [
                    _vm._v(_vm._s(report.vehicleName)),
                  ]),
                  _c("span", { staticClass: "extract-investor-date" }, [
                    _vm._v(_vm._s(report.reportDate)),
                  ]),
                  _vm.extractDownloadLoading &&
                  report.id === _vm.extractToDownload
                    ? _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          size: "20",
                          color: "primary",
                        },
                      })
                    : _c("span", { staticClass: "extract-investor-download" }, [
                        _vm._v(_vm._s(_vm.$t("download"))),
                      ]),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c(VPagination, {
        staticClass: "mt-6",
        attrs: { color: "primary", length: _vm.pageCount },
        on: { input: _vm.changePage },
        model: {
          value: _vm.page,
          callback: function ($$v) {
            _vm.page = $$v
          },
          expression: "page",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }